.banner {
  position: relative;
  overflow: hidden;
  margin-bottom: 40px;
  padding: 0;
  border-bottom: 1px solid #e5e5e5;
  box-shadow: 1px 1px #acacac;

  h2 {
    font-weight: 400;
  }

  a {
    color: palette(base, link-alt);
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 400;
    font-size: 14px;
    font-family: $heading-font-family-alt;

    .fa {
      margin-left: 5px;
    }
  }
}

.banner .banner-text {
  position: relative;
  z-index: 1;
  padding: 20px;
  height: 100%;
  border-bottom: 1px solid #ffffff;
  background: palette(elements, banner-bg);

  @include respond-to($bp-medium + 'px') {
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 3px 60px 40px 22px;
    width: 445px;
    height: 50%;
    border: 0;

    &:after {
      position: absolute;
      bottom: 0;
      left: -31px;
      display: block;
      width: 31px;
      height: 180px;
      background: transparent url('/themes/litchfield/assets/dist/images/banner-text-diagonal.png') no-repeat center left;
      background-size: 100% 100%;
      content: ' ';
    }

    h2 {
      font-size: 30px;

      &:before {
        position: absolute;
        left: 30px;
        content: '\f100';
        font-family: FontAwesome;
      }
    }

    h2,
    p,
    a {
      margin-top: 0;
      margin-bottom: 0;
      margin-left: 33px;
    }
  }

  @include respond-to($bp-large-min + 'px') {
    bottom: 15px;
    padding-bottom: 20px;
    height: 215px;
    background: rgba(0, 27, 77, 0.92);

    &:after {
      height: 215px;
    }

    h2,
    p,
    a {
      margin-top: 10px;
      margin-bottom: 10px;
    }

    a:hover {
      color: #ffffff;
    }

    &:before {
      border-bottom-width: 215px;
    }

    &:after {
      height: 100%;
      border-bottom-width: 215px;
    }
  }

  h2 {
    color: #95b1e5;
  }

  a {
    display: block;
    margin-top: 20px;
    color: $secondary-color;

    @include respond-to($bp-medium + 'px') {
      margin-top: 0;
    }

    @include respond-to($bp-large-min + 'px') {
      margin-top: 15px;
    }
  }

  p {
    color: palette(base, txt-light);
    font-size: 18px;
  }
}

.banner-image {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 0;
  width: 100%;
  height: auto;

  @include respond-to($bp-medium + 'px') {
    position: static;
  }

  img {
    margin: 0;
    min-height: 450px;
    width: 100%;

    @include mq($bp-medium $bp-large) {
      min-width: 0;
      min-height: 410px;
    }
  }
}

.banner-slide {
  max-height: 240px;

  @include respond-to($bp-medium + 'px') {
    max-height: 360px;
  }

  @include respond-to($bp-large-min + 'px') {
    max-height: 480px;
  }
}

.subpage-banner {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-bottom: 30px;
  height: 255px;
  border-bottom: 1px solid #e5e5e5;
  background: transparent url('/themes/litchfield/assets/dist/images/subpage-banner-gradient.png') repeat-x bottom left;
  box-shadow: 1px 1px #acacac;

  @include respond-to($bp-medium + 'px') {
    display: block;
    max-height: 255px;
    height: auto;
  }

  @include respond-to($bp-large + 'px') {
    max-height: 475px;
  }

  .banner-text-sub {
    margin: 0;
    min-height: 0;
    height: 100%;
    border-bottom: 0;
    background-size: cover;

    @include respond-to($bp-medium + 'px') {
      position: static;
      min-height: 250px;
      width: 100%;
    }

    >img {
      min-width: 100%;
      min-height: 100%;
    }

    span {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 88px;
      background: linear-gradient(to top, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0) 100%, rgba(0, 0, 0, 0) 100%);
      text-indent: 20px;

      @include respond-to($bp-medium + 'px') {
        left: 0;
        padding-left: 27px;
        height: 100%;
      }

      @include mq($bp-medium $bp-large) {
        width: 100%;
        text-align: left;
        text-indent: 0;
        white-space: normal;
        line-height: 45px;
      }
    }

    h1 {
      position: absolute;
      bottom: 0;
      margin: 0;
      padding: 0;
      color: #ffffff;
      white-space: nowrap;
      font-weight: 100;
      font-size: 45px;
      line-height: 70px;

      @include respond-to($bp-medium + 'px') {
        bottom: 25px;
        padding-left: 25px;
        font-size: 3em;
        line-height: 0.95em;
      }

      @include respond-to($bp-large-min + 'px') {
        bottom: 50px;
        font-size: 4em;
      }
    }
  }
}