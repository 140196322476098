article.login {

    position: relative;
    z-index: 1;
    padding: 0 20px;
    height: 170px;
    border-top: 1px solid $white;
    background: palette(elements, login-bg);

    @include respond-to($bp-medium + 'px') {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        padding-top: 5px;
        padding-right: 60px;
        padding-left: 24px;
        width: 419px;
        height: 215px;
        border: 0;

        &:after {
            position: absolute;
            bottom: 0;
            left: -31px;
            display: block;
            width: 31px;
            height: 215px;
            background: transparent url('/themes/litchfield/assets/dist/images/login-diagonal.png') no-repeat center left;
            background-size: 100% 100%;
            content: ' ';
        }

        &:before {
            left: -40px;
            border-right-width: 5px;
            border-right-color: #db8a05;
            border-bottom-color: #db8a05;
        }

        button,
        .btn.btn-primary {
            margin: 0 0 10px 0;
            max-width: none;
            width: 115px;
            text-align: center;
            white-space: nowrap;
            font-size: 16px;

            &:after {
                content: none;
            }
        }
    }

    @include respond-to($bp-large-min + 'px') {
        top: 15px;
        bottom: 55%;
    }

    @include mq($bp-medium $bp-large) {
        padding-top: 5px;
        height: 50%;

        h2 {
            font-size: 1.75em;
        }
    }

    h2 {
        @include respond-to($bp-medium + 'px', $bp-large + 'px') {
            line-height: 1.5em;
        }

        @include respond-to($bp-large-min + 'px') {
            margin-top: 12px;
            font-size: 34px;
        }

        color: #001b4d;
        letter-spacing: 1px;
        font-weight: 400;
        font-size: 28px;
        line-height: 1.25em;
    }

    .btn {
        float: left;
        font-size: 16px;
    }

    a {
        @include respond-to($bp-medium + 'px') {
            display: block;
            float: left;
            margin-top: 0;
            color: palette(base, link-alt);
            text-align: left;
            font-weight: 500;
            font-size: 0.85em;
        }

        @include respond-to($bp-large-min + 'px') {
            display: inline-block;
            margin-bottom: 20px;
        }

        position: relative;
        float: right;
        color: #001b4c;
        text-transform: capitalize;
        font-size: 0.8em;
        font-family: 'myriad-pro',
        sans-serif;
        line-height: 27px;

        &:hover {
            text-decoration: underline;
        }
    }

    input {
        @include respond-to($bp-medium + 'px') {
            margin-top: -2px;
            margin-bottom: 0;
            padding-bottom: 1px;
        }

        @include respond-to($bp-large-min + 'px') {
            margin-top: 0;
            padding-bottom: 2px;
        }

        margin-top: 0;
        margin-bottom: 1px;
        border-bottom: 1px solid #f2d591;
        color: #fff;
        font-size: 18px;
        line-height: 30px;

        &::-webkit-input-placeholder {
            color: palette(elements, input-placeholder);
        }

        &.last-input {
            margin-bottom: 15px;
        }
    }

    .signup {
        margin-left: 20px;
        letter-spacing: 0;
        font-size: 14px;
    }
}

.login-arrow {
    position: absolute;
    top: -1px;
    right: -14px;
    font-weight: 800;
    font-size: 1.25em;
    transform: scale(0.5, 1);
}

.subpage-banner {
    article.login {
        @include respond-to($bp-medium + 'px') {
            position: absolute;
            top: auto;
            bottom: 20px;
            padding-top: 15px;
            width: 419px;
            height: 215px;
            background-color: transparent;
        }

        position: static;
        top: 0;
        width: 100%;
        background-color: yellow;

        @include mq($bp-medium $bp-large) {
            bottom: 21px;
            padding-top: 7px;

            &:before {
                border-bottom: 250px #123e8f solid;
            }
        }

        h2 {
            @include respond-to($bp-large + 'px') {
                margin-top: 0;
            }

            margin-top: 10px;
        }
    }
}