body {
  background: #dbdbdb;
}

.logo {
  margin-left: 0;
  width: 70%;

  @include respond-to($bp-medium + 'px') {
    margin-left: 30px;
  }
  @include respond-to($bp-large-min + 'px') {
    float: left;
    margin-left: 40px;
    width: auto;
  }
  img {
    max-height: 85px;
    width: 212px;
    background: #ffffff;
  }
}

.rate-tracker-container {
  margin-bottom: 40px;
  border: 1px solid #afafad;
  background: palette(elements, cb-background) url('/themes/litchfield/assets/dist/images/bg-waves-gray.png');
  font-family: $heading-font-family-alt;

  @include respond-to($bp-large+'px') {
    display: flex;

    align-items: center;
  }
  @include mq($bp-medium $bp-large) {
    box-sizing: border-box;
    margin-bottom: 0;
    width: calc(35% - 2px);
  }
  p {
    margin-bottom: 0;
    padding: 10px 20px 0 20px;
    font-weight: 600;
    font-style: italic;
    font-size: 0.85em;
    line-height: 1.25em;

    @include respond-to($bp-large+'px') {
      padding-top: 0;
      padding-bottom: 0;
    }
    @include mq($bp-medium $bp-large) {
      padding: 10px 10px 0 10px;
      font-size: 0.85em;
    }
  }
  strong {
    display: block;
    font-weight: 800;
    line-height: 3em;

    @include respond-to($bp-large+'px') {
      display: inline;
      line-height: normal;
    }
  }
  button,
  .btn.btn-primary {
    display: block;
    margin-right: auto;
    margin-left: auto;
    min-width: 45%;
    font-size: 0.85em;

    @include respond-to($bp-large+'px') {
      margin: 0;
      margin-right: 20px;
      min-width: 0;
      width: 300px;
      height: 33px;
      white-space: nowrap;
      line-height: 28px;
    }
  }
  .rate-tracker {
    div[id*='valsum'],
    .disclaimer {
      display: none;
    }
    .btn {
      display: block;
      margin: 0 auto;
      padding: 0 15px;
      width: auto;
      border: 1px solid rgba(0, 47, 135, 0.8);
      border-radius: 500px;
      background: #ffffff;
      color: #002f87;
      text-transform: uppercase;
      font-weight: 700;
      font-style: normal;
      font-size: 0.85em;
      font-family: 'myriad-pro', sans-serif;
      cursor: pointer;
    }
  }
}

.help {
  margin-bottom: 30px;
  padding: 20px;
  background: $blue-gradient;
  background: url('/themes/litchfield/assets/dist/images/bg-waves.png'), $blue-gradient;
  h3 {
    margin-top: 0;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid $primary-color-light;
    color: $primary-color-light;
    text-transform: uppercase;
    font-weight: 400;
    font-family: $heading-font-family;
  }
  a {
    color: #f2a900;
    font-weight: 700;
    font-family: $myriad-semicondensed;
    &:hover,
    &:active {
      color: #f2a900;
    }
    &:before {
      margin-right: 10px;
      color: $primary-color-light;
      font-family: FontAwesome;
    }
    &.phone-icon:before {
      content: '\f095';
      font-size: 18px;
    }
    &.email-icon {
      color: #f2a900;
      &:before {
        content: '\f0e0';
      }
    }
  }
}
