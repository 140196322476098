.team {
  display: flex;
  flex-direction: row;
  width: 100%;

  flex-wrap: wrap;
}

.team-member {
  overflow: hidden;
  margin: 0 20px 20px 20px;
  max-width: 400px;
  width: calc(100% - 80px);

  @include respond-to($bp-medium + 'px') {
    width: calc(50% - 80px);
  }
  @include respond-to($bp-large + 'px') {
    width: calc(25% - 80px);
  }
  h4 {
    margin-top: 0;
  }
}

.team-image img {
  width: 100%;
}
