section.footer {
  padding-bottom: 80px;
  font-family: $heading-font-family-alt;

  @include respond-to($bp-medium + 'px') {
    margin: 0 30px;
  }
  @include respond-to($bp-large-min + 'px') {
    margin: 0 40px;
  }
  p {
    padding-top: 20px;
    border-top: 1px solid rgba($dark, 0.4);
    color: $dark;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    font-family: $myriad-semicondensed;
    line-height: 1.25em;

    @include respond-to($bp-medium + 'px') {
      padding-top: 10px;
      text-align: left;
      br {
        display: none;
      }
    }
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      width: 100%;
      text-align: center;
      font-weight: 500;
      font-size: 14px;
      font-family: $myriad;

      @include respond-to($bp-medium + 'px') {
        display: inline-block;
        width: auto;
        text-align: left;
        &:after {
          margin: 0 5px;
          color: #cccccc;
          content: '|';
        }
        &:last-child:after {
          content: '';
        }
      }
    }
  }
  .logos {
    margin: 20px auto;
    width: 50%;
    text-align: center;

    @include respond-to($bp-medium + 'px') {
      float: right;
      margin-top: -50px;
      width: auto;
      text-align: right;
    }
    img {
      display: inline-block;
      margin: 0 6px;
      width: 50px;
      vertical-align: middle;
      opacity: 0.6;
    }
  }
}
