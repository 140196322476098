.rates-container {
    width: 100%;

@include mq($bp-medium $bp-large) {
        box-sizing: border-box;
        margin-right: 2px;
        margin-bottom: 0;
        width: 65%;
    }
    &> div> div {
        display: flex;
        margin-bottom: 2px;
        padding: 30px 0;
        height: 100%;
        background: $blue-gradient;
        background: url('/themes/litchfield/assets/dist/images/bg-waves.png'), $blue-gradient;

        flex-wrap: wrap;
    }
}

.rate-details {
@include respond-to($bp-large-min + 'px') {
        width: calc(25% - 1px);
        border-right: 1px solid rgba($primary-color-light, 0.3);
        font-size: 1.25em;
    }

    margin-top: -1px;
    padding-bottom: 20px;
    width: calc(50% - 1px);
    text-align: center;
    &:first-child {
@include respond-to($bp-large-min + 'px') {
            border-bottom: 0;
        }

        border-right: 1px solid rgba($primary-color-light, 0.3);
        border-bottom: 1px solid rgba($primary-color-light, 0.3);
    }

    &:nth-child(3),
    &:last-child {
@include respond-to($bp-large-min + 'px') {
            padding-top: 0;
        }

        padding-top: 25px;
        padding-bottom: 0;
        button {
            margin-bottom: 0;
        }
    }

    &:nth-last-child(2) {
        border-right: 1px solid rgba($primary-color-light, 0.3);
    }

    &:last-child {
@include respond-to($bp-large-min + 'px') {
            border: 0;
        }

        border-top: 1px solid rgba($primary-color-light, 0.3);
    }

    button {
        padding: 0 10px;
        height: 30px;
        font-size: 0.8em;
        font-family: $myriad-semicondensed;
        line-height: 16px;
    }
}

.rate-title {
    color: palette(elements, rate-title);
    text-transform: uppercase;
    white-space: nowrap;
    font-size: 1em;
    font-family: $heading-font-family;
}

.rate {
    margin-bottom: 10px;
    color: $white;
    font-size: 2.5em;
    font-family: $heading-font-family;
    line-height: 1em;
}

.tablesaw-bar.mode-stack {
    display: none !important;
}

.rate-tracker-container {
@include respond-to($bp-medium + 'px') {
        padding-bottom: 0;
    }

@include respond-to($bp-large-min + 'px') {
        margin-top: 3px;
    }

    padding-bottom: 20px;
    max-width: 100%;
    .btn.btn-white {
        height: 25px;
        font-weight: 700;
        font-size: 0.9em;
        font-family: $myriad-semicondensed;
        line-height: 21px;
    }
}

.rates-configuration tr:first-child {
    background: palette(elements, table-head);
}
