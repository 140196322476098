.noUi-connect {
  background: palette(base, primary-color) !important;
}

.calculator-field-wrap {
  margin: 20px 0;
}

.calculator-input-row {
  padding-bottom: 5px;
}

.input-slider {
  margin-left: 15px;
}

.calculator-readout-panel {
  text-align: right;
  .calculator-readout-label {
    font-size: 1.25em;
    line-height: 1.1em;
  }
  .calculator-readout-value {
    font-size: 2em;
    line-height: 1.1em;
  }
}
