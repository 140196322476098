body {
  color: palette(elements, txt);
  font-weight: $base-font-weight;
  font-size: $base-font-size;
  font-family: $base-font-family;
  line-height: $base-line-height;
}

a,
.link {
  color: palette(base, link);
  text-decoration: none;

  &:visited {
    color: palette(base, link);
  }

  &:hover,
  &:visited:hover {
    color: palette(base, link-hover);
    text-decoration: none;
  }
}

.italic {
  font-style: italic;
}

.bold {
  font-weight: 700;
}

.dark {

  a,
  .link {
    color: palette(base, link-hover);

    &:visited,
    &:hover,
    &:visited:hover {
      color: palette(base, link-hover);
    }
  }
}

h1,
h2,
h4,
h5,
h6 {
  font-weight: $heading-font-weight;
  font-family: $heading-font-family;
}

h1,
h2 {
  color: palette(headings, 1);
  text-transform: uppercase;
  font-weight: 800;
  font-size: 30px;
  line-height: 1.75em;

  &.flag {
    height: 80px;
    background: transparent url('/themes/litchfield/assets/dist/images/rates-banner-title.png');
    background-size: 100% 100%;
    text-align: center;
    font-weight: 500;
    line-height: 67px;

    @include respond-to($bp-large+'px') {
      padding: 0 50px 0 20px;
      min-width: 250px;
      height: auto;
      border: 0;
      background: transparent url('/themes/litchfield/assets/dist/images/rates-banner-title-desktop.png');
      background-size: 100% 100%;
      white-space: nowrap;
      line-height: 100px;
    }

    @include respond-to($bp-medium+'px',
      $bp-large+'px') {
      font-size: 1.75em;
    }

    &:after {
      display: block;
      width: 100%;
      border-top: 20px solid;
    }
  }
}

h2 {
  border-top: 2px solid palette(headings, 2);
  font-size: 38px;

  &.no-top-border {
    border: 0;
  }

  &.top-border a {
    margin-top: 30px;
    line-height: 1em;
  }
}

.subpage h2 {
  border-bottom: 1px solid palette(headings, 2);

  &.no-top-border {
    border: 0;
  }
}

h3 {
  color: #001b4c;
  font-weight: 600;
  font-size: 28px;
  font-family: $myriad-semicondensed;
}

h3,
h4 {
  margin-top: 20px;
  text-transform: capitalize;
  line-height: 1.3;
}

h4 {
  color: #595959;
  font-weight: 700;
  font-size: 22px;
  font-family: $myriad;
}

h5,
h6 {
  color: #595959;
  text-transform: capitalize;
  font-size: 15px;
}

h2 a {
  float: right;
  margin-top: 5px;
  text-transform: Capitalize;
  font-size: 12px;

  @include respond-to($bp-medium+'px') {
    margin-top: 0;
  }
}

p {
  margin-bottom: 10px;
}

.block-margins {
  margin: 1em 0;
}

.unordered-list {
  list-style-type: disc;
}

.ordered-list {
  list-style: decimal;
}

blockquote {
  padding: 4px 0 4px 10px;
  border-left: 5px solid palette(base, primary-color);

  p {
    margin: 0;
    height: 100%;
    background: palette(base, primary-color);
    color: #ffffff;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-weight: 100;
    font-size: 18px;
    line-height: 72px;

    @include respond-to($bp-medium+'px') {
      padding: 26px 30px;
      text-align: left;
      line-height: 1.4em;
    }

    .highlight {
      font-weight: 400;
    }
  }
}

.homepage {
  .content {
    h2.top-border {
      width: 100%;
    }
  }
}

.search {
  table tbody tr:first-child {
    background: #001b4c;
  }
}

.editable {
  font-family: $heading-font-family-alt;

  h2 {
    margin-bottom: 15px;
    color: $midnight;
  }

  p,
  ul,
  ol,
  pre {
    font-weight: 400;
    line-height: 24px;
  }

  ul {
    position: relative;
    margin-left: 40px;
    list-style-type: none;

    @include font-size(14,
      14);
    @extend .unordered-list;

    &.disclaimers {
      margin: 0;
      padding: 20px 0;
      border-bottom: 1px solid palette(base, primary-color);
      text-indent: 40px;
    }

    li {
      padding: 0.15em 0;
      line-height: 1.5em;

      &:before {
        position: absolute;
        left: -15px;
        margin-right: 5px;
        color: palette(elements, bullet-point);
        content: '\f061';
        font-family: FontAwesome;
      }

      ul {
        margin: 0;
      }
    }
  }

  ol {
    margin-left: 40px;
    list-style: none;
    counter-reset: section;

    @include font-size(14,
      14);
    @extend .ordered-list;

    li {
      padding: 0.35em 0;
      line-height: 1.5em;

      ol,
      ul {
        margin: 0;
        counter-reset: section;

        li:before {
          margin: 0;
          content: '';
        }
      }

      &:before {
        margin-right: 8px;
        content: counter(section);
        counter-increment: section;
        font-weight: 800;
        font-family: $base-font-family;
      }
    }
  }

  .image,
  img {
    margin: 10px;

    &[style*='right'],
    .pull-right {
      margin-right: 0;
    }

    &[style*='left'],
    .pull-left {
      margin-left: 0;
    }

    img {
      width: 100%;
    }

    .caption {
      display: inline-block;
      padding: 0 0 1em 0;
      width: 100%;
      border-bottom: 1px solid rgba(palette(base, primary-color), 0.6);
      text-align: left;
      font-style: italic;

      @include font-size(14,
        16);
    }
  }

  blockquote {
    margin: 2em 0;
    padding: 0.85em 1.5em;
    width: 100%;
    height: auto;
    border-color: palette(elements, quote-border);
    color: palette(elements, quote);
    font-weight: 600;
    font-style: italic;
    font-family: 'Plume';
    line-height: 2em;

    p {
      background: transparent;
      color: palette(elements, quote);
      text-align: left;
      text-transform: capitalize;
      font-weight: 500;
      font-style: italic;
    }
  }

  table {
    padding: 10px 0;
    width: 100%;
    border-spacing: 0;
    font-size: 0.85em;

    thead {
      padding: 10px 0;
      background: palette(elements, table-head);
      font-size: 1em;
    }

    th {
      position: relative;
      padding: 20px 20px 10px;
      border-bottom: 3px solid palette(base, secondary-color);
      color: palette(elements, table-head-txt);
      vertical-align: bottom;
      text-align: left;
      text-transform: uppercase;
      font-weight: 600;
      line-height: 18px;

      &:after {
        position: absolute;
        top: 50%;
        right: 5px;
        display: block;
        float: right;
        height: 65%;
        border-right: 1px solid rgba(149, 177, 229, 0.4);
        content: '';
        transform: translateY(-50%);
      }

      &:last-child:after {
        content: none;
      }
    }

    td {
      padding: 10px 20px;
      border-bottom: 1px solid #95b1e6;
      background-color: palette(elements, table-cell);
      line-height: 18px;

      &:first-child {
        color: palette(base, primary-color);
        font-weight: 600;
      }
    }

    tr:nth-child(even) {
      td {
        background-color: palette(elements, table-cell-alt);
      }
    }
  }

  .two-column {
    column-count: 1;

    @include respond-to($bp-medium+'px') {
      column-count: 2;
    }

    h3,
    p {
      display: inline-block;
    }
  }
}


/*
 * Modifiers
 */

.float-left {
  float: left;
  margin-right: 1em;
}

.float-right {
  float: right;
  margin-left: 1em;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}