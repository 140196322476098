//Loan PQ

.nav-container .global-nav {
  @include respond-to($bp-large-min+ 'px') {
    position: absolute;
    top: -45px;
    left: 0;
    display: flex;
    width: 100%;
    border-bottom: 5px solid palette(base, secondary-color);
    background: #001c4c url(/themes/litchfield/assets/dist/images/bg-waves.png);

    justify-content: flex-end;

    ul {
      margin-right: 10px;

      li {
        display: inline-block;
        line-height: 40px;

        &:after {
          margin-left: 20px;
          padding-right: 20px;
          color: palette(elements, global-nav-txt);
          content: '|';
          opacity: 0.5;
        }

        a {
          color: palette(elements, global-nav-txt);
          text-transform: uppercase;
          font-weight: 700;
          font-size: 12px;
          font-family: $myriad-semiextended;

          .fa {
            margin-right: 3px;
            color: $secondary-color-light;
            vertical-align: middle;
            font-size: 1.25em;
          }

          &:hover {
            color: #ffffff;

            .fa {
              color: #1f92cc;
            }
          }
        }
      }
    }

    .search {
      display: inline-block;
      margin-right: 40px;
      padding: 0;
      width: 120px;
      height: 40px;
      border: 0;
      font-weight: 600;
      font-family: 'Plume', serif;
      transition: all 0.5s ease-out;

      &.focus {
        margin-left: -23px;
        padding: 0 20px;
        width: 250px;

        input {
          outline: none;
          color: #ffffff;

          &::-webkit-input-placeholder {
            color: palette(elements, global-nav-txt);
          }

          &:-moz-placeholder {
            /* Firefox 18- */
            color: palette(elements, global-nav-txt);
          }

          &::-moz-placeholder {
            /* Firefox 19+ */
            color: palette(elements, global-nav-txt);
          }

          &:-ms-input-placeholder {
            color: palette(elements, global-nav-txt);
          }
        }

        .fa-search {
          color: $secondary-color-light;
        }
      }

      input {
        padding: 0;
        height: 40px;
        border: 0;
        color: palette(elements, search-txt);
        font-size: 14px;

        &::-webkit-input-placeholder {
          color: palette(elements, search-txt);
        }

        &:-moz-placeholder {
          /* Firefox 18- */
          color: palette(elements, search-txt);
        }

        &::-moz-placeholder {
          /* Firefox 19+ */
          color: palette(elements, search-txt);
        }

        &:-ms-input-placeholder {
          color: palette(elements, search-txt);
        }
      }

      .fa-search {
        top: 10px;
        right: 20px;
        color: $secondary-color-light;

        &:hover {
          color: #1f92cc;
        }
      }
    }
  }

  a {
    color: palette(elements, global-nav-txt);

    &:hover {
      color: #cedbf2;

      .fa {
        color: #cedbf2;
      }
    }
  }

  .search input {
    color: palette(elements, search-txt);

    &::-webkit-input-placeholder {
      color: palette(elements, search-txt);
    }

    &:-moz-placeholder {
      /* Firefox 18- */
      color: palette(elements, search-txt);
    }

    &::-moz-placeholder {
      /* Firefox 19+ */
      color: palette(elements, search-txt);
    }

    &:-ms-input-placeholder {
      color: palette(elements, search-txt);
    }
  }
}

.sidebar {
  div[id*='pnlSubPages'] {
    @include mq($bp-medium $bp-large) {
      margin-right: 30px;
      width: 50%;
      background: #001b4d;
    }
  }

  h2.no-content {
    display: none;
  }

  ul.ul1 {
    margin-bottom: 30px;
    padding: 0;
    background: #001b4d;
    list-style: none;

    li {
      margin: 0 20px;
      border-bottom: 1px solid rgba(palette(elements, nav-item-lvl2-border), 0.2);
      text-transform: uppercase;
      font-weight: 600;
      font-size: 0.95em;
      font-family: $myriad-condensed;

      &:last-child {
        border-bottom: 0;
      }

      a {
        display: block;
        padding: 0;
        color: #cedbf2;
        font-weight: 600;
        font-size: 1em;
        line-height: 45px;

        &.active {
          position: relative;

          &:before {
            position: absolute;
            left: -20px;
            display: block;
            width: 5px;
            height: 100%;
            background: #ffffff;
            content: '';
          }
        }
      }
    }
  }
}