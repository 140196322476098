.alert-container {
  margin-top: 0;
  padding: 15px 20px;
  background: #1f92cc;
  box-shadow: inset 0 -4px 12px -2px rgba(0, 0, 0, 0.5);
  color: #001b4c;
  font-size: 0.85em;
  transition: margin-top 0.5s ease-in-out;
}

.alert {
  margin: 0 auto;
  max-width: 90vw;

  .fa-warning {
    margin-right: 15px;
    color: #80d4ff;
    font-size: 2.25em;
  }

  .fa-close {
    position: relative;
    top: -3px;
    color: #135b80;
    font-size: 2em;
    cursor: pointer;
  }
}

.alert-text {
  display: inline-block;
  margin-right: 30px;
  width: calc(100% - 120px);
  color: palette(elements, alert-txt);
  vertical-align: top;
  text-decoration: none;
  font-weight: 600;
  font-style: italic;
  font-family: $heading-font-family-alt;

  h3 {
    display: inline-block;
    margin: 0;
    margin-right: 5px;
    color: palette(elements, alert-heading);
    text-transform: uppercase;
    font-weight: 400;
    font-style: normal;
    font-size: 1rem;
  }

  &:hover,
  &:visited,
  &:active {
    color: palette(elements, alert-txt) !important;
    text-decoration: none !important;
  }
}