body.nav-open {
  overflow: hidden;
  height: 100vh;
}

button.menu {
  position: absolute;
  top: 55%;
  right: 20px;
  margin: 0;
  padding: 0;
  min-width: 0;
  border: none;
  background: transparent;
  color: palette(base, primary-color);
  font-size: 2em;
  cursor: pointer;
  transform: translateY(-50%);

  @include respond-to($bp-medium + 'px') {
    right: 50px;
  }
  @include respond-to($bp-large-min + 'px') {
    display: none;
  }

  &:hover {
    border: 0;
    background: transparent;
  }
  &:focus {
    outline: none;
  }
}

.nav-container {
  position: fixed;
  top: 0;
  right: 0;
  left: auto;
  z-index: 10;
  display: none;
  margin: 0;
  padding: 0 0 0 20px;
  width: 100%;
  height: 100vh;
  background: palette(elements, nav-bg);
  color: palette(base, txt-light);
  font-family: $heading-font-family-alt;

  @include mq($bp-medium $bp-large) {
    right: -300px;
    left: auto;
    display: block;
    box-sizing: border-box;
    width: 290px;
    box-shadow: -5px 0 10px rgba(0, 0, 0, 0.25);
    transition: right 0.5s ease-in-out;
  }
  @include respond-to($bp-large-min + 'px') {
    position: static;
    display: block;
    margin: 0 0 0 auto;
    height: auto;
    background: transparent;
  }
  .site-nav {
    @include respond-to($bp-large-min + 'px') {
      float: right;
      margin-top: 40px;
      margin-right: 20px;
    }
  }
  &.open {
    display: block;
    overflow-y: scroll;

    @include mq($bp-medium $bp-large) {
      right: 0;
    }
  }
  .fa {
    color: $secondary-color;
  }
  .close-btn {
    height: 55px;
    border-bottom: 1px solid palette(elements, nav-item-lvl1-border);
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 1.25em;
    font-family: $heading-font-family;
    line-height: 55px;
    cursor: pointer;

    @include respond-to($bp-large-min + 'px') {
      display: none;
    }
    .fa-close {
      vertical-align: text-bottom;
      font-size: 1.2em;
    }
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    &.global-nav li {
      margin-right: 20px;
      height: 45px;
      border-bottom: 1px solid rgba(palette(elements, nav-item-lvl2-border), 0.2);
      text-transform: uppercase;
      font-size: 0.85em;
      line-height: 48px;
    }
  }
  .search {
    position: relative;
    .fa-search {
      position: absolute;
      top: 20px;
      right: 23px;
    }
    input {
      margin-bottom: 0;
      height: 50px;
      border-bottom: 1px solid palette(elements, nav-item-lvl1-border);
      font-size: 0.85em;
    }
  }
  .ul1 {
    .li1 {
      position: relative;
      margin-right: 20px;
      border-bottom: 1px solid rgba(palette(elements, nav-item-lvl2-border), 0.2);
      line-height: 50px;
      cursor: pointer;

      @include respond-to($bp-large-min + 'px') {
        display: inline-block;
        padding-bottom: 10px;
        border-bottom: none;
        vertical-align: bottom;
      }
      .a1 {
        display: block;

        @include respond-to($bp-large-min + 'px') {
          display: inline;
        }
      }
      &:hover > a {
        color: #666666;
      }
      &.has_children:hover .ul2 {
        @include respond-to($bp-large-min + 'px') {
          position: absolute;
          z-index: 10;
          display: block;
          overflow: visible;
          margin-top: 10px;
          padding: 20px;
          width: 200px;
          height: auto;
          background: palette(elements, nav-bg);

          &:before {
            position: absolute;
            top: -10px;
            left: 20px;
            display: block;
            width: 0;
            height: 0;
            border-top: 0;
            border-right: 10px solid transparent;
            border-bottom: 10px solid palette(elements, nav-bg);
            border-left: 10px solid transparent;
            content: ' ';
          }
        }
      }
      &.has_children:after {
        position: absolute;
        top: 0;
        right: 0;
        z-index: -1;
        display: block;
        color: palette(base, secondary-color);
        content: '\f067';
        font-size: 1.5em;
        font-family: FontAwesome;

        @include respond-to($bp-large-min + 'px') {
          content: none;
        }
      }
      &.has_children.open:after {
        content: '\f068';

        @include respond-to($bp-large-min + 'px') {
          content: none;
        }
      }
      a {
        color: #ffffff;
        font-weight: 600;
        font-size: 1em;

        @include respond-to($bp-large-min + 'px') {
          color: #002f87;
        }
      }
    }
  }
  .ul2 {
    display: none;
    overflow: hidden;
    height: 0;
    font-weight: 500;
    font-size: 0.85em;
    .li2 {
      height: auto;
      line-height: 1.5em;
      a {
        color: #cedbf2;
        font-weight: 500;
        line-height: 2em;
        &:hover {
          color: #f2a900;
        }
      }
    }
    &.open {
      margin-bottom: 10px;
      padding-top: 10px;
      height: auto;
      border-top: 1px solid rgba(palette(elements, nav-item-lvl2-border), 0.2);

      @include respond-to(0, $bp-large + 'px') {
        position: static;
        display: block;
        margin: 0;
        width: 100%;
      }
    }
  }
}
