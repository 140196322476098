.quick-links {
  position: relative;
  margin-bottom: 40px;
  padding: 20px 30px;
  border: 0;
  border-top: 1px solid palette(base, secondary-color);
  border-bottom: 1px solid #afafaf;
  background: transparent url('/themes/litchfield/assets/dist/images/bg-waves-gray.png');
  list-style-position: inside;
  list-style-type: none;

  @include mq($bp-medium $bp-large) {
    margin-bottom: 0;
  }

  @include respond-to($bp-medium+'px') {
    height: calc(100% - 65px);
  }

  li {
    position: relative;
    margin: 9px 0;
    color: #002f87;
    font-weight: 600;
    font-family: 'myriad-pro', sans-serif;
    line-height: 0.9em;

    @extend .clear-after;

    a {
      display: block;
      float: left;
      font-weight: 600;
      font-family: 'myriad-pro', sans-serif;
      line-height: 20px;
    }

    &:before {
      position: absolute;
      top: -2px;
      left: -10px;
      color: #db8a06;
      content: '\f0da';
      font-family: FontAwesome;
      line-height: 20px;
    }
  }
}

.subpage .quick-links {
  @include respond-to($bp-large+'px') {
    height: auto;
  }
}