button,
input[type='submit'],
.news-search a,
.btn.btn-primary,
.btn.btn-default {
  margin: 0 10px;
  padding: 0 15px;
  min-width: 100px;
  max-width: 90%;
  width: auto;
  border: 2px solid transparent;
  border-radius: 500px;
  background: palette(elements, btn-off);
  color: palette(elements, btn-off-txt);
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  font-family: $myriad-semicondensed;
  cursor: pointer;

  @include respond-to($bp-medium) {
    font-family: $heading-font-family-alt;
  }
  &.btn-light {
    background: palette(elements, btn-light-off);
  }
  &.btn-white,
  &:hover {
    border: 2px solid palette(elements, btn-sec-border);
    background: palette(elements, btn-sec-off);
    color: palette(elements, btn-sec-off-txt);
    font-style: normal;
  }
  &.btn-white:hover {
    border-color: #ffffff;
    background: $primary-color;
    color: #ffffff;
  }
}

.form-box-content input[type='submit'] {
  display: block;
  margin: 10px 10px 0 auto;
  margin-bottom: 30px;
}

.news-search {
  position: relative;
  margin-bottom: 20px;
  a {
    position: absolute;
    top: 3px;
    right: 0;
  }
}

.search .fa-search {
  cursor: pointer;
}
