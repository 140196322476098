.speedbump {
  position: fixed;
  top: -100%;
  left: 50%;
  z-index: 1000;
  padding: 20px;
  min-width: 300px;
  max-width: 100%;
  width: 50%;
  border: 1px solid $primary-color;
  background: #ffffff;
  transition: all 0.5s ease-out;
  transform: translateX(-50%) translateY(-50%);
  &.reveal {
    top: 50%;
  }
  .close-reveal-modal {
    position: absolute;
    top: 5px;
    right: 20px;
  }
  .close-speedbump {
    cursor: pointer;
  }
}

.speedbump-mask {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  opacity: 0;
  transition: all 0.5s ease-out;
  &.reveal {
    z-index: 999;
    opacity: 1;
  }
}

.speedbump-buttons {
  display: inline-block;
  float: right;
  a {
    display: inline-block;
  }
}
