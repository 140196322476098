$g18: #2e2e2e;
$g19: #303030;
$g20: #333333;
$g21: #363636;
$g22: #383838;
$g23: #3b3b3b;
$g24: #3d3d3d;
$g25: #404040;
$g26: #424242;
$g27: #454545;
$g28: #474747;
$g29: #4a4a4a;
$g30: #4d4d4d;
$g31: #4f4f4f;
$g32: #525252;
$g33: #555555;
$g34: #575757;
$g35: #595959;
$g36: #5c5c5c;
$g37: #5e5e5e;
$g38: #616161;
$g39: #636363;
$g40: #666666;
$g42: #6b6b6b;
$g43: #6e6e6e;
$g44: #707070;
$g45: #737373;
$g46: #757575;
$g47: #787878;
$g48: #7a7a7a;
$g49: #7d7d7d;
$g50: #7f7f7f;
$g51: #828282;
$g52: #858585;
$g53: #878787;
$g54: #8a8a8a;
$g55: #8c8c8c;
$g56: #8f8f8f;
$g57: #919191;
$g58: #949494;
$g59: #969696;
$g60: #999999;
$g61: #9c9c9c;
$g62: #9e9e9e;
$g63: #a1a1a1;
$g64: #a3a3a3;
$g65: #a6a6a6;
$g69: #b0b0b0;
$g70: #b3b3b3;
$g71: #b5b5b5;
$g72: #b8b8b8;
$g73: #bababa;
$g74: #bdbdbd;
$g75: #bfbfbf;
$g76: #c2c2c2;
$g77: #c4c4c4;
$g78: #c7c7c7;
$g79: #c9c9c9;
$g80: #cccccc;
$g81: #cfcfcf;
$g82: #d1d1d1;
$g83: #d4d4d4;
$g84: #d6d6d6;
$g85: #d9d9d9;
$g86: #dbdbdb;
$g87: #dedede;
$g88: #e0e0e0;
$g89: #e3e3e3;
$g90: #e5e5e5;
$g91: #e8e8e8;
$g92: #ebebeb;
$g93: #ededed;
$g94: #f0f0f0;
$g95: #f2f2f2;
$g97: #f7f7f7;
$g98: #fafafa;
$g99: #fcfcfc;


$base-font-size: 100%;

// Base Colors
$link-water: #cedbf2;
$cornflower: #95b1e5;
$torea-bay: #0c398d;
$resolution-blue: #002f87;
$blue-zodiac: #122b5a;
$midnight: #001f45;
$lime: #b7ff00;
$atlantis: #92bd25;
$trendy-green: #6a8a1c;
$turtle-green: #2f3d0c;
$white: #ffffff;
$dark: #666666;
$web-orange: #f2aa00;
$tahiti-gold: #db8a05;

$persian-rd: #d52d2d;
$dark-tn: #77111d;

// Base Color Settings
//-----Primary
$primary-color: $resolution-blue;
$primary-color-light: $cornflower;
$primary-color-dark: $blue-zodiac;

//-----Secondary
$secondary-color-light: $web-orange;
$secondary-color: $tahiti-gold;
$secondary-color-dark: $tahiti-gold;

//Gradients
$header-gradient: linear-gradient(0deg, #e5e5e5, #dbdbdb);
$blue-gradient: linear-gradient(0deg, $resolution-blue, $midnight);

$palettes: (
  base: (bg: $white,
    txt: $dark,
    txt-light: $link-water,
    link: #002f87,
    link-hover: #363636,
    content-link-hover: #595959,
    link-alt: #002f87,
    link-alt-hover: #363636,
    primary-color: $primary-color,
    secondary-color: $secondary-color ),
  headings: ( // h(n)
    1: #001b4c,
    2: $secondary-color-dark ),
  elements: ( //alert
    alert-bg: $persian-rd,
    alert-txt: #000000,
    alert-close:#000000,
    alert-icon: #000000,
    alert-heading:#000000,

    //nav
    nav-bg: $midnight,
    nav-item-lvl1-border: $primary-color,
    nav-item-lvl2-border: $primary-color-light,
    nav-icon: $primary-color-light,
    global-nav-txt: #cccccc,
    global-nav-bg: $secondary-color,
    search-txt: #cccccc,

    //Block Quote
    quote-border: $secondary-color,
    quote: $primary-color,

    //Calculators
    calc-accent: $primary-color,

    //Banner
    banner-bg: $midnight,
    login-bg: url(/themes/litchfield/assets/dist/images/bg-waves-orange.png),

    //Input
    input-bg: transparent,
    input-placeholder: #001b4c,
    input-border: #f2d591,
    textarea: #f2f4f9,
    textarea-border: $cornflower,

    //Buttons
    btn-off: $secondary-color-light,
    btn-off-txt: #001b4b,
    btn-over: $white,
    btn-over-txt: $primary-color,

    btn-light-off: #f2d591,

    //Lists
    bullet-point: $secondary-color,

    //Content Box
    cb-background: $cornflower,

    //Accordion
    acc-border: #afafaf,
    acc-border-hover: #1f92cc,
    acc-border-open: $secondary-color,
    acc-text: #001b4d,
    acc-text-hover: #000000,
    acc-text-open: $primary-color,

    //Tabs
    tab: #f2a900,
    tab-txt: #001b4d,
    tab-selected: #001b4c,
    tab-selected-txt: #cccccc,
    tab-hover: #1f92cc,
    tab-hover-txt: #001b4d,
    tab-content: #f2f4f9,

    //Secondary Light Buttons
    btn-sec-off: $white,
    btn-sec-off-txt: $primary-color,
    btn-sec-border: rgba($primary-color, 0.8),
    btn-sec-over: $white,
    btn-sec-over-txt: $primary-color,

    //Table Colors
    table-head-txt: #cccccc,
    table-head: #001b4c,
    table-cell: #f2f4f9,
    table-cell-alt: #e5eaf3,

    //Rates table
    rate-title: #95b1e3)
);

//Typography
$myriad-semiextended: 'Fira Sans', sans-serif;
$myriad-semicondensed: 'Fira Sans Condensed', sans-serif;
$myriad-condensed: 'Fira Sans Condensed', sans-serif;
$myriad: 'Fira Sans', sans-serif;
$plume: 'Livvic', sans-serif;

$base-font-family: $plume;
$base-font-size: 17px;
$base-font-weight: 300;
$base-line-height: 1.6;

$heading-font-family: $myriad-condensed;
$heading-font-weight: 700;
$heading-font-family-alt: $myriad;

// usage background: palette(grays, 1);
@function palette($palette, $tone: 'base') {
  @return map-get(map-get($palettes, $palette), $tone);
}