section.header {
  position: relative;
  padding: 20px 0 0 0;
  border-bottom: 1px solid #acacac;
  background: palette(base, primary-color) url('/themes/litchfield/assets/dist/images/bg-waves.png') repeat top left;

  @include respond-to($bp-large-min + 'px') {
    margin-top: 45px;
    padding-top: 0;
  }
}

.header-content {
  padding: 20px;
  border-top: 5px solid palette(base, secondary-color);
  border-bottom: 1px solid #acacac;
  background: $header-gradient;
  box-shadow: 1px 1px #e5e5e5;

  @include respond-to($bp-large-min + 'px') {
    padding: 15px 20px 0;
    border-top: 0;
  }
}
