* {
  margin: 0;
  padding: 0;
}

body {
  overflow-x: hidden;

  @include mq($bp-large) {
    overflow-x: auto;
  }
}

form {
  background: #dbdbdb;
}

section {
  padding: 0 20px;
  &.main {
    display: flex;
    flex-direction: column-reverse;

    @include respond-to($bp-medium + 'px') {
      flex-direction: row;
      padding: 0 50px;
    }
    @include respond-to($bp-large-min + 'px') {
      flex-direction: row;
      padding: 0 60px;
    }
  }
  .content {
    @include respond-to($bp-medium + 'px') {
      width: 100%;
    }
    @include mq($bp-medium $bp-large) {
      margin-bottom: 40px;
      h2 {
        width: 100%;
      }
    }
  }
  .sidebar {
    @include respond-to($bp-medium + 'px') {
      margin-right: 60px;
      margin-bottom: 40px;
      width: 100%;

      flex-basis: 33%;
    }
    @include respond-to($bp-large-min + 'px') {
      margin-right: 60px;
      max-width: 350px;
      max-width: none;

      flex-basis: 31%;
      .featured-content {
        float: left;
        width: 60%;
      }
    }
  }
}

.homepage {
  @include respond-to($bp-large-min + 'px') {
    .sidebar {
      display: block;
    }
  }
  section .content {
    display: flex;

    align-content: flex-start;
    flex-wrap: wrap;
  }
}

.subpage {
  article.content {
    margin-bottom: 40px;
  }
  section.main {
    margin-bottom: 75px;

    @include mq($bp-medium $bp-large) {
      flex-direction: column-reverse;
      .featured-content {
        width: 50%;
      }
    }
    @include respond-to($bp-large-min + 'px') {
      .sidebar {
        display: block;
      }
    }
  }
}
