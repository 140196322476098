.location {
  display: block;
  margin: 20px 0;
  width: 100%;
  border: 1px solid #dedede;
  background: #ffffff;

  @include respond-to($bp-medium + 'px') {
    display: flex;
    width: 80%;
  }

  img {
    margin: 0;
    padding: 15px;
    vertical-align: top;
  }

  > div {
    display: inline-block;
    padding: 15px;
    &.loc-image {
      padding: 0;
      width: 100%;
      border-right: 1px solid #dedede;
      background: #f2f2f2;

      @include respond-to($bp-medium + 'px') {
        width: auto;
      }
    }
  }

  ul {
    margin-left: 20px;
    break-inside: avoid;

    @include respond-to($bp-medium + 'px') {
      margin-left: 40px;
    }
  }

  li:first-child {
    text-indent: -21px;
    &:before {
      display: none;
    }
  }
}
