input,
textarea {
  margin-bottom: 0;
  padding: 0 0 5px 0;
  width: 100%;
  border: 0;
  border-bottom: 2px solid $secondary-color;
  background: transparent;
  color: #595959;
  font-style: italic;
  font-size: 1.1em;
  &[type='checkbox'],
  &[type='radio'] {
    float: left;
    margin-top: 6px;
    margin-right: 10px;
    width: auto;
  }
  div[id*='Checkbox'] {
    margin-top: 40px;
  }
  &::-webkit-input-placeholder {
    color: #595959;
    font-style: italic;
    opacity: 1;
  }
}

fieldset > div {
  position: relative;
  margin-bottom: 30px;

  label {
    position: absolute;
    bottom: -25px;
  }
}

input {
  padding: 5px 0 0 0;
}

fieldset div[id*='pnlFile'] {
  margin-bottom: 40px;
}

fieldset div[id*='TextArea'],
fieldset div[id*='Checkbox'] {
  position: static;
  label {
    position: static;
  }
}

textarea {
  border: 1px solid palette(elements, textarea-border);
  background: palette(elements, textarea);
}

fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}

label {
  display: block;
  margin-bottom: 20px;
  color: #595959;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.95em;
  &:first-child {
    margin: 20px 0 0 0;
  }
}

.form,
.content-form {
  div {
    vertical-align: top;
  }
  .col-1 {
    display: inline-block;
    margin-right: 22px;
    width: calc(8.3333% - 22px);

    @include media(max-width 768px) {
      width: 100%;
    }
  }
  .col-2 {
    display: inline-block;
    margin-right: 22px;
    width: calc(16.6666% - 22px);

    @include media(max-width 768px) {
      width: 100%;
    }
  }
  .col-3,
  .quarter {
    display: inline-block;
    margin-right: 22px;
    width: calc(25% - 22px);

    @include media(max-width 768px) {
      width: 100%;
    }
  }
  .col-4,
  .third {
    display: inline-block;
    margin-right: 22px;
    width: calc(33.3333% - 22px);

    @include media(max-width 768px) {
      width: 100%;
    }
  }
  .col-5 {
    display: inline-block;
    margin-right: 22px;
    width: calc(40% - 22px);

    @include media(max-width 768px) {
      width: 100%;
    }
  }
  .col-6,
  .half {
    display: inline-block;
    margin-right: 22px;
    width: calc(50% - 22px);

    @include media(max-width 768px) {
      width: 100%;
    }
  }
  .col-7 {
    display: inline-block;
    margin-right: 22px;
    width: calc(60% - 22px);

    @include media(max-width 768px) {
      width: 100%;
    }
  }
  .col-8 {
    display: inline-block;
    margin-right: 22px;
    width: calc(66.6666% - 22px);

    @include media(max-width 768px) {
      width: 100%;
    }
  }
  .col-9 {
    display: inline-block;
    margin-right: 22px;
    width: calc(75% - 22px);

    @include media(max-width 768px) {
      width: 100%;
    }
  }
  .col-10 {
    display: inline-block;
    margin-right: 22px;
    width: calc(83.3333% - 22px);

    @include media(max-width 768px) {
      width: 100%;
    }
  }
  .col-11 {
    display: inline-block;
    margin-right: 22px;
    width: calc(91.6666% - 22px);

    @include media(max-width 768px) {
      width: 100%;
    }
  }
  .col-12,
  .full {
    width: 100%;
  }
  .last {
    margin-right: 0;
  }
}
